import store from '@/store'
// this.$forceUpdate();
export default [{
        title: 'Application',
        route: 'eca',
        icon: 'HomeIcon',
        is_allowed: "true"
    },
    {
        title: 'User',
        route: 'ecan',
        icon: 'HomeIcon',
        is_allowed: "true"
    },
    // {
    //   title: store.state.app.user.username,
    //   route: 'Users',
    //   icon: 'UserIcon',
    //   is_allowed: "true"
    // },
    // {
    //   title: 'Organizations',
    //   route: 'Organizations',
    //   icon: 'FolderIcon',
    //   is_allowed: "true"
    // },
    // {
    //   title: 'Scanners',
    //   route: 'Scanners',
    //   icon: 'BoxIcon',
    //   is_allowed: store.state.app.user.is_staff
    // },
    // {
    //   title: 'Assets',
    //   icon: 'GridIcon',
    //   is_allowed: store.state.app.user.is_staff,
    //   children: [
    //     {
    //       title: 'Asset Types',
    //       route: 'AssetTypes',
    //       icon: 'PackageIcon',
    //     },
    //     {
    //       title: 'Assets',
    //       route: 'Assets',
    //       icon: 'BriefcaseIcon',
    //     },
    //   ],
    // },
    // {
    //   title: 'My Assets',
    //   route: 'Assets',
    //   icon: 'BriefcaseIcon',
    //   is_allowed: !store.state.app.user.is_staff
    // },
    // {
    //   title: 'Vulnerabilities',
    //   route: 'All Vulnerabilities',
    //   icon: 'FolderIcon',
    //   is_allowed: store.state.app.user.permissions.includes("asset.list_vulnerabilities")
    // },
]